import ArticleTile from "../ContentTiles/ArticleTile";
import { PlaylistTile } from "../ContentTiles/PlaylistTile";
import { VideoTile } from "../ContentTiles/VideoTile";
import "./ContentGrid.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { MediaType, CollectionType, ApiAsset, SearchResultContentItem } from "../../utils/ApiTypes";
import { TopicTile } from "../ContentTiles/TopicTile";
import { GenericTile } from "../ContentTiles/GenericTile";


interface Props {
    content: ApiAsset[] | SearchResultContentItem[];
    showTitle?: boolean;
    showDesription?: boolean;
    playlistId?: number;
    className?: string;
    contentHeader?: string;
    maxItems?: Maybe<number>;
    showButton?: boolean;
    handleClickSeeButton?: () => void;
    showCollectionName?: boolean;
}

const ContentGrid: React.FC<Props> = (props) => {
    const { content, showTitle, showDesription, contentHeader } = props;
    const { t } = useTranslation();
    const showCollectionName = props.showCollectionName ?? true;

    function handleSeeButton(){
        props.handleClickSeeButton && props.handleClickSeeButton();
    }

    return (
        <div className={`content-grid-component ${props.className}`}>
            {showCollectionName &&
                <div className={`h6-bold grid-header`}>{contentHeader}
                    {props.showButton && <a className="see-all-button button-link" onClick={handleSeeButton} >{props.maxItems ? t("see all") : t("see less")}</a>}
                </div>
            }
            <div className={`content-grid-container ${(showDesription) && "bigger-row-gap"}`}>
                {content.slice(0, props.maxItems ?? content.length).map((item: ApiAsset) => {
                    switch (item.type) {
                        case CollectionType.Playlist:
                            return <PlaylistTile playlist={item} showTitle={showTitle} showDescription={showDesription} />
                        case MediaType.Video:
                            return <VideoTile video={item} showTitle={showTitle} showDescription={showDesription} playlistId={props.playlistId}/>
                        case MediaType.Audio:
                            return <VideoTile video={item} showTitle={showTitle} showDescription={showDesription} playlistId={props.playlistId}/>
                        case MediaType.Article:
                            return <ArticleTile item={item} />
                        case CollectionType.Topic:
                            return <TopicTile topic={item} />
                        default:
                            return <GenericTile title={(item as any).title} path={(item as any).path}/>
                    }
                }).map((tile, i) => (
                    <div key={i} className='content-tile'>
                        {tile}
                    </div>
                ))
                }
            </div>
        </div>
    );
};

export default ContentGrid;