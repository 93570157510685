import './InviteLovedOnesPage.scss';
import * as React from 'react';
import {useContext} from 'react';
import {IonContent, IonPage} from "@ionic/react";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { useTranslation } from 'react-i18next';
import { Feature, useHasFeatureEnabled } from '../../components/Feature/Feature';
import { InvitationForm } from '../../components/InvitationForm/InvitationForm';
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";

export const InviteLovedOnesPage: React.FC = () => {

    const { currentUser } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const { t } = useTranslation();
    const hasEap: boolean = useHasFeatureEnabled("has-eap");

    return (
        <IonPage className={`invite-loved-ones-page`}>
            <ResponsiveNavigationMenu title={t("Share")} />
            <IonContent className='invite-loved-ones-content'>
                <Feature feature='invite-loved-ones'>
                    <div className={"invitation-form"}>
                        <InvitationForm fullPage isComponentVisible={isComponentVisible} />
                        <div className="invitation-form-info">
                            <div className="caption">
                                {hasEap &&
                                    <>
                                        {t("Your loved one will receive 2 emails: One with the EAP phone number and a second email granting them full access to Journey Proactive EAP")}
                                    </>
                                }
                                {!hasEap &&
                                    <>
                                        {t("Your loved ones can access Journey’s world-class proactive mental health content, completely free of charge. Whether it's your spouse, child, significant other, roommate, or other family members, with Journey LIVE they can receive the support they need.")}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Feature>
            </IonContent>
        </IonPage>
    );
};
