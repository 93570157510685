import { useContext, useState } from "react";
import "./InvitationForm.scss";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { IonButton, IonIcon, IonInput, IonItem } from "@ionic/react";
import InviteIcon from "../../assets/images/invite-white.svg";
import { useTranslation } from "react-i18next";
import AnalyticsService from "../../misc/AnalyticsService";
import { useLocation } from "react-router-dom";
import { useHasFeatureEnabled } from "../Feature/Feature";

interface InvitationFormProps {
    isComponentVisible: boolean;
    fullPage?: boolean;
}

export function InvitationForm(props: InvitationFormProps) {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {handleGeneralError, handleGeneralMessage, handleUserError, currentUser, language} = useContext(ApplicationContext);
    const { t } = useTranslation();
    const location = useLocation();
    const [formValues, setFormValues] = useState({
        firstName: null,
        lastName: null,
        email: null
    });
    const hasEap: boolean = useHasFeatureEnabled("has-eap");

    function handleValueChange(name: string, value: string | null, event?: any) {
        setFormValues({...formValues, [name]: value});
    }

    async function handleInvitation() {
        if(currentUser?.company.type === "trial"){
            handleUserError(t("This feature is not available for Trial users."), " ");
            return;
        } 
        if (!formValues.firstName || formValues.firstName === "") {
            handleUserError(t("Incorrect value for First Name"), t("First Name field can not be empty"));
            return;
        }
        if (!formValues.lastName || formValues.lastName === "") {
            handleUserError(t("Incorrect value for Last Name"), t("Last Name field can not be empty"));
            return;
        }
        if (!formValues.email || formValues.email === "") {
            handleUserError(t("Incorrect value for Email"), t("Email field can not be empty"));
            return;
        }
        if (formValues.email && !validateEmail(formValues.email)) {
            handleUserError(t("Invalid Email format"), t("Invalid Email format"));
            return;
        }
        try {
            setIsLoading(true);
            await JourneyApiClient.inviteFriendAndFamily(formValues.email, formValues.firstName, formValues.lastName, language?.abbreviation ?? 'en');
            handleGeneralMessage(t("Success"), t("Invitation sent!"));
            setFormValues({
                firstName: null,
                lastName: null,
                email: null,
            });
            trackInvitationSent();
        } catch (e) {
            handleGeneralError(e, undefined, { 
                contextName: "Invitation failed", 
                contextData: {
                    userId: currentUser?.id,
                    language: language,
                    company: currentUser?.company,
                    formValues: formValues
                } 
            });
        }
        setIsLoading(false);
    }

    function validateEmail(email: string) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }

    async function trackInvitationSent() {
        await AnalyticsService.trackUserAction("invite_form_invitation", location.pathname);
    }

    return(
        <div className={"invitation-form-component"}>
            <IonItem className={`title-item ${props.fullPage ? 'header-4' : 'subtitle1-variant title'}`}>
                {
                    props.fullPage ?
                        hasEap ? t("Invite Loved Ones to Journey Proactive EAP"): t("Invite Loved Ones to Journey LIVE") :
                        t("Invite Loved Ones")
                }
            </IonItem>
            <div className={`header-text ${props.fullPage ? 'body-large' : 'caption'}`}>
                {
                    props.fullPage ?
                        hasEap ? t("At Journey Proactive EAP, we believe that everyone deserves access to the support they need to strengthen their mental health. That’s why we provide free access to our platform for the friends and family of all employees who participate in our program.")
                            : t("Did you know that you can gift Journey LIVE to family and friends? It's as easy as sending them this invite.")
                            : t("Your friends and family can use all of Journey’s features completely for free.")
                }
            </div>
            <IonItem className={"form-item"}>
                <IonInput
                    className={"input name body-small"}
                    placeholder={`${t("First Name")}*`}
                    value={formValues.firstName}
                    onIonChange={e => handleValueChange("firstName", e.detail.value ?? null)}
                />
            </IonItem>
            <IonItem className={"form-item"}>
                <IonInput
                    className={"input lastname body-small"}
                    placeholder={`${t("Last Name")}*`}
                    value={formValues.lastName}
                    onIonChange={e => handleValueChange("lastName", e.detail.value ?? null)}/>
            </IonItem>
            <IonItem className={"form-item"}>
                <IonInput
                    className={"input email body-small"}
                    placeholder={`${t('Email')}*`}
                    value={formValues.email}
                    onIonChange={e => handleValueChange("email", e.detail.value ?? null)}/>
            </IonItem>
            <IonButton onClick={handleInvitation} className={"send-invite-button button-medium-variant"} disabled={isLoading}>
                {
                    props.fullPage ?
                        t("Send invitation") :
                        <>{t("Send Invite")} <IonIcon className={"invite-icon"} src={InviteIcon}/></>
                }

            </IonButton>
        </div>
    )
}
