import {useContext, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import { ApplicationContext } from "../../misc/ApplicationContext";
import "./ContentModulePage.scss";
import {ApiResourcesContentModule, ResourceContent} from "../../utils/ApiTypes";
import {IonContent, IonPage} from "@ionic/react";
import {ResourcesCarrousel} from "../../components/Resources/ResourcesCarrousel";
import {ResourcesTile} from "../../components/Resources/ResourcesTile";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import { ResourcesBackButton } from "../../components/Resources/ResourcesBackButton";
import { ResponsiveNavigationMenu } from "../../components/Navigation/ResponsiveNavigationMenu";
import {BACKGROUND_TILE_COLOR} from "../../utils/utils";

type ResourcesRouteParams = {
    divisionId: string;
};

export type ResourcesTileProps = {
    content: ResourceContent;
};

export function ContentModulePage(props: ResourcesTileProps) {

    const [isModulesLoading, setIsModulesLoading] = useState<boolean>(true);
    const {isMobileWidth, handleGeneralError, currentUser, language} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const location = useLocation();
    const [modules, setModules] = useState<ApiResourcesContentModule[]>([]);
    const [divisionTitle, setDivisionTitle] = useState<string>('');
    const params = useParams<ResourcesRouteParams>();


    useEffect(() => {
        if(isComponentVisible){
            getContentModule(params.divisionId);
            setIsModulesLoading(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    useEffect(() => {
        if (!isComponentVisible) return;
        getContentModule(params.divisionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible, language]);

    async function getContentModule(divisionId: string) {
        try {
            setIsModulesLoading(true);
            const list = await JourneyApiClient.getResourcesContentModuleByDivisionId(divisionId);
            setModules(list);
            const division = await JourneyApiClient.getResourcesContentByDivisionId(divisionId);
            setDivisionTitle(division.nameNative);
            setIsModulesLoading(false);
        } catch (error) {
            setIsModulesLoading(false);
            handleGeneralError(error, undefined, { 
                contextName: "Unable to fetch Resources Content Module By DivisionId", 
                contextData: {
                    userId: currentUser?.id,
                    company: currentUser?.company,
                    divisionId: divisionId,
                }
            });
        }
    }

    return(
        <IonPage className="content-module-page">

            <ResponsiveNavigationMenu title={"Resources"} />

            <IonContent className="module-content">
                <ResourcesCarrousel isComponentVisible={isComponentVisible} isMobileWidth={isMobileWidth} />
                {isModulesLoading ? <PleaseWait/> :
                    <div className="resources-tiles-wrapper">

                        <div className="module-title-container">
                            {!isMobileWidth && <ResourcesBackButton className='back' />}
                            <div className="module-title header-5-variant">{divisionTitle}</div>
                        </div>
                        <div className="resources-tiles-container">
                            {modules && modules.map((contentModule: ApiResourcesContentModule, index: number) => {
                                return(<ResourcesTile color={BACKGROUND_TILE_COLOR[index % BACKGROUND_TILE_COLOR.length]} key={index} displayBorder={true} text={contentModule.nameNative} i18nKey={`resources-module-${(contentModule.nameEnglish).toLowerCase()}`} link={`${location.pathname}/${contentModule.id}`} />)
                            })}
                        </div>
                    </div>}
            </IonContent>
        </IonPage>
    )
}