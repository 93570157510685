import { useQuery } from '@tanstack/react-query';
import { ApiResourcesContentLanguage } from '../../utils/ApiTypes';
import { useContext } from 'react';
import { ApplicationContext } from '../../misc/ApplicationContext';
import { JourneyApiClient } from '../../utils/JourneyApiClient';

// Custom hook that uses useQuery to fetch languages
export default function useAppLanguages(companyId: number | undefined): {languages: ApiResourcesContentLanguage[]} {

    const {language, handleGeneralError, currentUser} = useContext(ApplicationContext);

    async function getUserLanguages(companyId: number): Promise<ApiResourcesContentLanguage[]> {
        try{
            if(!companyId) return [];
            const response = await JourneyApiClient.getResourcesLanguages(companyId);
            const countryLanguages = response.filter(lang => lang.countryId); // avoid "base" locales
            countryLanguages.sort(compareLanguages);
    
            return countryLanguages;
        } catch (e){
            handleGeneralError(e, undefined, { 
                contextName: "Could not get languages", 
                contextData: {
                    userId: currentUser?.id,
                    company: currentUser?.company,
                }
            });
            return [];
        }
    }

    function compareLanguages(a: ApiResourcesContentLanguage, b: ApiResourcesContentLanguage) {
        // sort by country first, preferring:
        //    current county (if any), then
        //    US first (if not current), then
        //    others by county code
    
        // within a country, sort by abbreviation
    
        let comparison = 0;

        if (language?.countryId) {
            if (a.countryId === language.countryId && b.countryId !== language.countryId) {
                comparison = -1;
            } else if (b.countryId === language.countryId && a.countryId !== language.countryId) {
                comparison = 1;
            }
        }
    
        if (comparison === 0) {
            if (a.countryId !== b.countryId) {
                if (a.countryId === "US") {
                    comparison = -1;
                }
                else if (b.countryId === "US") {
                    comparison = 1;
                }
                else {
                    comparison = a.countryId.localeCompare(b.countryId);
                }
            }
        }
    
        if (comparison === 0) {
            comparison = a.abbreviation.localeCompare(b.abbreviation);
        }
    
        return comparison;
    }

    // useQuery to fetch and cache the languages list
    const { data, error, isLoading, isError } = useQuery<ApiResourcesContentLanguage[], Error>(
        ['getUserLanguages', companyId, language], // Query key (unique for companyId)
        () => getUserLanguages(companyId!), // Function to fetch languages
        {
            enabled: !!companyId, // Only fetch when companyId is provided
            refetchOnMount: false, // Do not refetch on mount
        }
    );

    return {
        languages: data ?? [], // Return languages or empty array
    };
};
