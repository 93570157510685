import {useContext, useEffect, useState} from 'react';
import { ApiMonthlyCheckinAnswerV101, User } from '../../utils/ApiTypes';
import { JourneyApiClient } from '../../utils/JourneyApiClient';
import { ApplicationContext } from '../../misc/ApplicationContext';


/**
 * 
 * @param user
 * @returns isMatch: boolean, isCheckLoading: boolean 
 */
export default function useMonthlyAssessmentProgress(user: User): {assessmentProgress: number, isMonthlyAssessmentCompleted: boolean, isMonthlyAssessmentProgressLoading: boolean} {
    
    const [isMonthlyAssessmentCompleted, setIsMonthlyAssessmentCompleted] = useState<boolean>(false);
    const [isMonthlyAssessmentProgressLoading, setIsMonthlyAssessmentProgressLoading] = useState<boolean>(true);
    const [assessmentProgress, setAssessmentProgress] = useState<number>(-1);

    const {handleGeneralError, handleHasCompletedAssessment, hasCompletedAssessment, currentUser, language} = useContext(ApplicationContext);

    useEffect(() => {
        if(hasCompletedAssessment){
            setAssessmentProgress(8);
            setIsMonthlyAssessmentCompleted(true);
            setIsMonthlyAssessmentProgressLoading(false);
        } else {
            setIsMonthlyAssessmentProgressLoading(true);
            JourneyApiClient.getMonthlyAssessmentV101().then((monthlyAssessment: ApiMonthlyCheckinAnswerV101) => {
                getAssessmentProgress(monthlyAssessment);
                setIsMonthlyAssessmentProgressLoading(false);
            }).catch((e) => {
                handleGeneralError(e, undefined, { 
                    contextName: "Could not fetch latest assessment", 
                    contextData: {
                        userId: currentUser?.id,
                        language: language,
                        company: currentUser?.company
                    } 
                });
                setIsMonthlyAssessmentProgressLoading(false);
            });
        }
    }, [hasCompletedAssessment]);
    
    useEffect(() => {
        setIsMonthlyAssessmentProgressLoading(true);
        JourneyApiClient.getMonthlyAssessmentV101().then((monthlyAssessment: ApiMonthlyCheckinAnswerV101) => {
            getAssessmentProgress(monthlyAssessment);
            setIsMonthlyAssessmentProgressLoading(false);
        }).catch((e) => {
            handleGeneralError(e, undefined, { 
                contextName: "Could not fetch latest assessment", 
                contextData: {
                    userId: currentUser?.id,
                    language: language,
                    company: currentUser?.company
                } 
            });
            setIsMonthlyAssessmentProgressLoading(false);
        });
    }, []);
    
    function getAssessmentProgress (monthlyAssessment: ApiMonthlyCheckinAnswerV101): number {
        //In order to allow the user to continue on the last step they left we need to know where they stopped
        //We will return the following step, this will be the first step uncompleted
        //To know which step corresponds to which question, see renderAssessment() in AssessmentModal.tsx
        let progress: number = 0;
        if(monthlyAssessment.stressLevel){
            progress = 1;
        }
        if(monthlyAssessment.feelingsFrequency){
            progress = 2;
        }
        if(monthlyAssessment.manageStressLevel){
            progress = 3;
        }
        if(monthlyAssessment.feelingsFrequencyAffectWork){
            progress = 4;
        }
        if(monthlyAssessment.daysEmotionallyChallenging){
            progress = 5;
        }
        if(monthlyAssessment.priorities !== null && (JSON.parse(monthlyAssessment.priorities)).length > 0){
            //At this point the user has completed the assessment if career and interests have been completed previously
            progress = 6;
        }
        if(progress === 6 && user.career !== null){
            progress = 7;
        }
        if(progress === 7 && user.interests !== null){
            progress = 8;
        }
        handleHasCompletedAssessment(progress >= 6);
        setIsMonthlyAssessmentCompleted(progress >= 6);
        setAssessmentProgress(progress);
        return progress;
    }
    
    return {assessmentProgress, isMonthlyAssessmentCompleted, isMonthlyAssessmentProgressLoading};    
}