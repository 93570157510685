import React, {useContext} from "react";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import "./WorklifeButtonsContainer.scss";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { useQuery } from "@tanstack/react-query";
import { ApiResourcesContentDivision } from "../../utils/ApiTypes";
import { WorklifeButtonsContainer } from "./WorklifeButtonsContainer";


export const WorklifeComponent: React.FC = () => {

    const { handleGeneralError, currentUser, language } = useContext(ApplicationContext);

    async function getWorklifeContent(): Promise<ApiResourcesContentDivision[] | null> {
        try {
            return await JourneyApiClient.getWorklifeContentDivisions();
        } catch (e) {
            handleGeneralError(e, undefined, { 
                contextName: "Could not fetch Work Life content", 
                contextData: {
                    userId: currentUser?.id,
                    language: language,
                    company: currentUser?.company
                }
            });

            return null;
        }
    }
    const worklifeQuery = useQuery<Promise<ApiResourcesContentDivision[] | null>, unknown, ApiResourcesContentDivision[]>
        (["getWorklifeContentDivisions"], getWorklifeContent);
    
    return(
        <div className="worklife-component">
            {worklifeQuery.status === "success" && worklifeQuery.data.length > 0 &&
                <WorklifeButtonsContainer worklifeContent={worklifeQuery.data} />}
        </div>
    );
}
