import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage
} from "@ionic/react";
import {useHistory} from "react-router-dom";
import "./CommunicationsOptInPage.scss";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {
    HOME_PATH
} from "../../utils/utils";
import { SuccessMessage } from '../../components/SuccessMessage/SuccessMessage';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { useTranslation } from 'react-i18next';

export const CommunicationsOptInPage: React.FC = () => {
    const [isMarketingOptInCompleted, setMarketingOptInCompleted] = useState<boolean>(false);
    const {handleGeneralError, currentUser} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        (async function() {
            if(isComponentVisible){
                try {
                    await JourneyApiClient.userMarketingOptIn();
                    setMarketingOptInCompleted(true);
                } catch(e) {
                    handleGeneralError(e, t("Unable to opt in communication emails. Try again!") ?? undefined, { 
                        contextName: "Unable to opt in communication emails", 
                        contextData: {
                            userId: currentUser?.id,
                            company: currentUser?.company
                        }
                    });

                    history.push(HOME_PATH);
                }
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    return(
        <IonPage className={`communications-page`}>

            <ResponsiveNavigationMenu title={"Communications Opt In"} />

            <IonContent className="communications-page-content">
                {isMarketingOptInCompleted ?
                    <SuccessMessage isComponentVisible={isComponentVisible} redirectToHome={true}/>
                    :
                    <PleaseWait/>
                }
            </IonContent>
        </IonPage>
    )
};
