import "./AssessmentModal.scss";
import * as React from "react";
import {IonModal} from "@ionic/react";
import {User} from "../../utils/ApiTypes";
import AnalyticsService from "../../misc/AnalyticsService";
import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {AssessmentWelcomeStep} from "./AssessmentWelcomeStep";
import {useHistory} from "react-router-dom";
import useMonthlyAssessmentProgress from "../CustomHooks/useMonthlyAssessmentProgress";

interface assessmentModalProps {
    monthlyAssessmentIsAllowed: boolean;
    hasSeenMonthlyAssessment: boolean;
    user: User;
}

export const AssessmentModal: React.FC<assessmentModalProps> = (props: assessmentModalProps) => {

    const {handleGeneralError, hasCompletedAssessment, currentUser} = useContext(ApplicationContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [hasSeenModal, setHasSeenModal] = useState<boolean>(false);
    const {assessmentProgress, isMonthlyAssessmentProgressLoading} = useMonthlyAssessmentProgress(props.user);
    const history = useHistory();

    useEffect(() => {
        if(!props.user) return;
        if(hasCompletedAssessment) return;
        if(isMonthlyAssessmentProgressLoading) return;
        getLatestAssessment(assessmentProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[hasCompletedAssessment,isMonthlyAssessmentProgressLoading, ]);

    async function trackAssessmentOpened(trigger: "automatic"){
        await AnalyticsService.trackUserAction("monthly_assessment_opened", null, {trigger});
    }

    async function getLatestAssessment (assessmentStep: number) {
        try {
            const day = new Date().getDate();
            if((day <= 3 && assessmentStep < 6) || !props.hasSeenMonthlyAssessment){
                //Adding condition to stop spammin in the same session
                if(!hasSeenModal){
                    setIsModalOpen(true);
                    setHasSeenModal(true);
                    trackAssessmentOpened("automatic");
                }
            }
        } catch (e) {
            handleGeneralError(e, undefined, { 
                contextName: "Could not fetch latest assessment", 
                contextData: {
                    userId: currentUser?.id,
                    company: currentUser?.company,
                    assessmentStep: assessmentStep
                } 
            });
        }
    }


    function handleNextStepNavigation() {
        history.push("/assessment");
        setIsModalOpen(false);
    }

    return(
        <IonModal
            className={`assessment-modal-component fit-content`}
            showBackdrop={true}
            isOpen={isModalOpen && props.monthlyAssessmentIsAllowed}
            initialBreakpoint={.98}
            onWillDismiss={() => setIsModalOpen(false)}>
                <AssessmentWelcomeStep
                    handleNextStepNavigation={handleNextStepNavigation}
                    handleModalDismiss={() =>setIsModalOpen(false)}
                />
        </IonModal>
    )
}
