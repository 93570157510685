import * as React from 'react';
import { useContext, useState } from "react";
import {
    IonContent,
    IonPage,
} from "@ionic/react";
import {IonButton, IonSelect, SelectChangeEventDetail, IonSelectOption} from "@ionic/react";
import "./PostRegistrationQuestionsPage.scss";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { ApiCompany } from "../../utils/ApiTypes";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { PleaseWait } from "../../components/PleaseWait/PleaseWait";
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { useHasFeatureEnabled } from "../../components/Feature/Feature";
import { useTranslation } from "react-i18next";
import AnalyticsService from "../../misc/AnalyticsService";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

export const PostRegistrationQuestionsPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { handleGeneralError, currentUser, isMobileApp, silentlyRefreshUserData } = useContext(ApplicationContext);
    const [departmentId, setDepartmentId] = useState<number | null>(currentUser?.departmentId ?? null);
    const [tierId, setTierId] = useState<number | null>(currentUser?.tierId ?? null);
    const [locationId, setLocationId] = useState<number | null>(currentUser?.locationId ?? null);
    const hasEap = useHasFeatureEnabled("has-eap");
    const { t } = useTranslation();
    const location = useLocation();

    const companyQuery = useQuery<Promise<ApiCompany | null>, unknown, ApiCompany>(
        ["fetchCompany"],
        fetchCompany
    );

    const submitDisabled = (companyQuery.data?.departments?.length && companyQuery.data?.requiresDepartment && !departmentId ? true : false)
        || (companyQuery.data?.tiers?.length && companyQuery.data?.requiresTier && !tierId ? true : false)
        || (companyQuery.data?.locations?.length && companyQuery.data?.requiresLocation && !locationId ? true : false);

    async function fetchCompany(): Promise<ApiCompany | null> {
        try {
            if (!currentUser || !currentUser.company) return null;
            const company = await JourneyApiClient.getCompany(currentUser?.company.code);
            return company;
        } catch (error) {
            handleGeneralError(error, undefined, { 
                contextName: "Unable to fetch company", 
                contextData: {
                    userId: currentUser?.id,
                    company: currentUser?.company
                }
            });

            return null;
        }
    }

    const submitForm = async () => {
        setIsLoading(true);
        try {
            await JourneyApiClient.saveUserDivisions({
                departmentId: departmentId,
                tierId: tierId,
                locationId: locationId,
            });
            trackSaveDivisionsEvent();
            await silentlyRefreshUserData();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            handleGeneralError(error, undefined, { 
                contextName: "Unable to update user", 
                contextData: {
                    userId: currentUser?.id,
                    company: currentUser?.company
                }
            });
        }
    }

    const trackSaveDivisionsEvent = async () => {
        await AnalyticsService.trackUserAction("user_divisions_updated", location.pathname || null);
    }

    return (
        <IonPage className="post-registration-questions-page">

            <ResponsiveNavigationMenu title={t("Welcome to Journey!")} hideMenuButtons={true} />

            <IonContent className="post-registration-questions-content">
                {companyQuery.status === "loading" ? <PleaseWait /> :
                    <div className="registration-questions-wrapper">
                        {
                            hasEap ?
                                <h1 className="header-5">{t("Welcome to Journey Proactive EAP")}</h1>
                                :
                                <h1 className="header-5">{t("Welcome to Journey!")}</h1>
                        }
                        <h3 className="message header-6">{t("Make the following selections to finish your sign up and start using Journey confidentially and for free!")}</h3>

                        {companyQuery.data && companyQuery.data.locations && companyQuery.data?.locations.length > 0 && companyQuery.data.requiresLocation && (
                            <IonSelect
                                interface={isMobileApp ? "alert" : "popover"}
                                placeholder={`${t('Location')}*`}
                                onIonChange={(e: CustomEvent<SelectChangeEventDetail<number>>) => setLocationId(e.detail.value)}
                                value={locationId}
                                className="ion-select button-medium">
                                {
                                    companyQuery.data?.locations.map((location, index) => {
                                        return <IonSelectOption key={index} value={location.id}>{location.name}</IonSelectOption>
                                    })
                                }
                            </IonSelect>
                        )}

                        {companyQuery.data && companyQuery.data.departments && companyQuery.data.departments.length > 0 && companyQuery.data?.requiresDepartment && (
                            <IonSelect
                                interface={isMobileApp ? "alert" : "popover"}
                                placeholder={`${t('Department')}*`}
                                onIonChange={(e: CustomEvent<SelectChangeEventDetail<number>>) => setDepartmentId(e.detail.value)}
                                value={departmentId}
                                className="ion-select button-medium">
                                {
                                    companyQuery.data?.departments.map((dept, index) => {
                                        return <IonSelectOption key={index} value={dept.id}>{dept.name}</IonSelectOption>
                                    })
                                }
                            </IonSelect>
                        )}

                        {companyQuery.data && companyQuery.data.tiers && companyQuery.data.tiers.length > 0 && companyQuery.data?.requiresTier && (
                            <IonSelect
                                interface={isMobileApp ? "alert" : "popover"}
                                placeholder={`${t('Tier')}*`}
                                onIonChange={(e: CustomEvent<SelectChangeEventDetail<number>>) => setTierId(e.detail.value)}
                                value={tierId}
                                className="ion-select button-medium">
                                {
                                    companyQuery.data?.tiers.map((tier, index) => {
                                        return <IonSelectOption key={index} value={tier.id}>{tier.name}</IonSelectOption>
                                    })
                                }
                            </IonSelect>
                        )}

                        <IonButton type='submit' className='paper-submit-button-variation' onClick={submitForm} disabled={submitDisabled || isLoading} >
                            {t('submit')}
                        </IonButton>
                    </div>
                }
            </IonContent>
        </IonPage>
    )
}
