import { useState, useRef, useEffect } from 'react';
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';

export default function useIsComponentVisible(): boolean {
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useIonViewWillEnter(() => {
    if (isMounted.current) setIsComponentVisible(true);
  });

  useIonViewWillLeave(() => {
    if (isMounted.current) setIsComponentVisible(false);
  });

  return isComponentVisible;
}
