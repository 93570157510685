import "./../VideoListingPage/VideoListingPage.scss";
import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {IonContent, IonItemDivider, IonPage} from "@ionic/react";
import SwiperCore, {FreeMode, Navigation} from 'swiper';
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {useHistory, useLocation} from "react-router-dom";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import AnalyticsService from "../../misc/AnalyticsService";
import FooterComponent from "../../components/Footer/FooterComponent";
import { CategoryHeader } from '../../components/ContentTiles/CategoryHeader';
import { PUBLIC_WATCH_PATH } from '../../utils/utils';
import { ContentCarousel } from "../../components/ContentTiles/ContentCarousel";
import { VideoCategoryResponse, ContentItem } from "../../utils/ApiTypes";
import {LoginModalComponent} from "../../components/LoginModalComponent/LoginModalComponent";
import {CheckinGraph} from "../../components/CheckinGraph/CheckinGraph";
import {DailyPractice} from "../../components/DailyPractice/DailyPractice";
import {ActivityCalendar} from "../../components/ActivityCalendar/ActivityCalendar";
import {AssessmentPopover} from "../../components/AssessmentPopover/AssessmentPopover";
import { ResponsiveNavigationMenu } from "../../components/Navigation/ResponsiveNavigationMenu";

export const VideoListingPage: React.FC = () => {
    const [recommendations, setRecommendations] = useState<ContentItem[]>([]);
    const [collections, setCategories] = useState<VideoCategoryResponse[]>([]);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
    const [isContentLoading, setIsContentLoading] = useState<boolean>(true);
    const { currentUser, handleGeneralError, subscribeToDailyJourney } = useContext(ApplicationContext);
    const [continueWatchingList, setContinueWatchingList] = useState<ContentItem[]>([])
    const [savedList, setSavedList] = useState<ContentItem[]>([])
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();
    const location = useLocation();

    const shouldLogin = new URLSearchParams(location.search).get("login");

    SwiperCore.use([Navigation, FreeMode]);

    useEffect(() => {
        if (shouldLogin === "true") {
            setIsLoginModalOpen(true);
        }
    }, [shouldLogin]);

    useEffect(() => {
        if (!isComponentVisible) return;

        setIsContentLoading(true);
        const contentRequests = [
            getHomeContent(),
            getRecommendations()
        ];
        if (currentUser) {
            contentRequests.push(getContinueWatching());
            contentRequests.push(getSavedContent());
        }
        Promise.all(contentRequests).then(() => setIsContentLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);


    useEffect(() => {
        if (subscribeToDailyJourney && !currentUser) {
            setIsLoginModalOpen(true);
        }
    }, [subscribeToDailyJourney, currentUser]);

    const getContinueWatching = async () => {
        if (!currentUser || !currentUser?.privileged) return;
        try {
            const videos = await JourneyApiClient.getContinueWatching();
            setContinueWatchingList(videos);
        } catch (ex) {
            handleGeneralError(ex, undefined, {
                contextName: "Could not get continue watching videos",
                contextData: {
                    userId: currentUser?.id,
                    company: currentUser?.company
                }
            });
        }
    }

    const getSavedContent = async () => {
        if (!currentUser || !currentUser?.privileged) return;
        try {
            const videos = await JourneyApiClient.getSavedContent();
            setSavedList(videos);
        } catch (ex) {
            handleGeneralError(ex, undefined, {
                contextName: "Could not get saved videos",
                contextData: {
                    userId: currentUser?.id,
                    company: currentUser?.company
                }
            });
        }
    }

    const getHomeContent = () => {
        return JourneyApiClient.getHomeContent()
            .then(result => setCategories(result.videoCategories))
            .catch(error =>
                handleGeneralError(error, undefined, {
                    contextName: "Could not get home content",
                    contextData: {
                        userId: currentUser?.id,
                        company: currentUser?.company
                    }
                })
            );
    }
 
    const openLoginModal = () => {
        if (currentUser && currentUser.privileged) return;
        AnalyticsService.trackUserAction("view_login_modal", location.pathname);
        setIsLoginModalOpen(true);
    }

    const getRecommendations = async () => {
        const recommendations = await JourneyApiClient.getDefaultRecommendations();
        setRecommendations(recommendations);
    }

    function handleImageCarouselClick() {
        //Checking if the path we are going is a public video, if not we open the login modal
        //We have to have this const here because we avoid a race condition with the history.location.pathname
        const isPublicVideo = history.location.pathname.includes(PUBLIC_WATCH_PATH.replace(":id", ""));
        if(!isPublicVideo){
            openLoginModal();
        }
    }

    const now = new Date();
    const greeting = now.getHours() < 5 ? 'Hi Night Owl' :
        now.getHours() < 12 ? `Good Morning` :
            now.getHours() < 17 ? `Good Afternoon`
                : `Good Evening`;

    return (
        <IonPage className={`video-listing-page ${isLoginModalOpen ? "blur-content" : ""}`} id="main-content">

            <ResponsiveNavigationMenu handleOpenLoginModal={openLoginModal} />

            <LoginModalComponent
                isComponentVisible={isComponentVisible}
                isModalOpen={isLoginModalOpen}
                closeModal={() => setIsLoginModalOpen(false)}
            />
            <IonContent className={"main-content-container"}>
                <div className="home-page-daily-checkin">
                     <h6 className="user-greeting-text h6-bold">{greeting}</h6>
                    <div className="checkin-steps-container">
                        <CheckinGraph onCheckin={() => {}} openLoginModal={openLoginModal}/>
                        <DailyPractice state={'placeholder'}/>
                        <ActivityCalendar />
                    </div>
                </div>
                {
                    <>
                        <div className="video-listing-row">
                            <CategoryHeader
                                name={'Recommended'}
                                description={null}
                                onClick={openLoginModal}
                            >

                                <AssessmentPopover/>
                            </CategoryHeader>
                            <div className={"industry-targeting-row-wrapper"}>
                                <ContentCarousel onSaveChange={getSavedContent} onClick={handleImageCarouselClick} items={recommendations}/>
                            </div>
                        </div>
                        <div  className="home-page-line-divider-container"><IonItemDivider className="home-page-line-divider"/></div>
                    </>
                }
                {
                    (continueWatchingList.length > 0) &&
                    <>
                        <div className="video-listing-row">
                            <CategoryHeader
                                name='Continue Watching'
                                description={null}
                                onClick={openLoginModal}
                            />
                            <div className={"industry-targeting-row-wrapper"}>
                                <ContentCarousel onClick={handleImageCarouselClick} items={continueWatchingList}/>
                            </div>
                        </div>
                        <div className="home-page-line-divider-container"><IonItemDivider
                            className="home-page-line-divider"/></div>
                    </>
                }
                {
                    (savedList.length > 0) &&
                    <>
                        <div className="video-listing-row">
                            <CategoryHeader
                                name='My List'
                                description={null}
                                onClick={openLoginModal}
                            />
                            <div className={"industry-targeting-row-wrapper"}>
                                <ContentCarousel onClick={handleImageCarouselClick} items={savedList}/>
                            </div>
                        </div>
                        <div className="home-page-line-divider-container"><IonItemDivider
                            className="home-page-line-divider"/></div>
                    </>
                }
                {
                    collections.map((category, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="video-listing-row" >
                                    <CategoryHeader
                                        name={category.name}
                                        description={category.shortDescription}
                                        // path={`/category/${category.id}`}
                                        onClick={openLoginModal}
                                    />
                                    <div className={"industry-targeting-row-wrapper"}>
                                        <ContentCarousel onClick={handleImageCarouselClick} items={category.items}/>
                                    </div>    
                                </div>
                                {index < collections.length - 1 &&
                                <div className="home-page-line-divider-container"><IonItemDivider
                                    className="home-page-line-divider"/></div>}
                            </React.Fragment>
                        )
                    })

                }

                {!isContentLoading && <FooterComponent/>}
            </IonContent>
        </IonPage>
    );

}
