import * as React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import "./MiniLanguageMenuList.scss";
import {
    IonIcon,
    IonItem,
    IonList
} from "@ionic/react";
import { ApplicationContext } from "../../misc/ApplicationContext";
import Check from "../../assets/images/check-grey.svg";
import { ApiResourcesContentLanguage } from "../../utils/ApiTypes";
import "flag-icons/css/flag-icons.min.css";

interface Props {
    sendToHome: boolean;
    companyId: number | undefined;
    languages: ApiResourcesContentLanguage[];
}

export const MiniLanguageMenuList: React.FC<Props> = (props: Props) => {

    const { isMobileWidth, language, changeLanguage, handleToggleMiniLanguageMenu } = useContext(ApplicationContext);
    const history = useHistory();

    function handleLanguageClick(lang: ApiResourcesContentLanguage): void {
        changeLanguage(lang);
        if (props.sendToHome) history.push("/");
        handleToggleMiniLanguageMenu();
    }

    function getClassForLanguage(iLanguage: ApiResourcesContentLanguage) {
        return `${!isMobileWidth && language?.abbreviation === iLanguage.abbreviation ? 'selected' : ''}  menu-item`;
    }

    return (
        <IonList className='mini-language-menu-list-component'>
            {
                props.languages && props.languages.length > 0 && props.languages.map((iLanguage, index) => {
                    return (
                        <IonItem key={index} className={getClassForLanguage(iLanguage)} onClick={() => handleLanguageClick(iLanguage)}>
                            <div key={index} className={`caption language-name`}>
                                <span className={`flag fi fi-${iLanguage?.alpha2CodeISO ?? "us"}`} />
                                {iLanguage.localizedCountryName} - {iLanguage.localizedLanguageName}
                            </div>
                            {(isMobileWidth && language?.abbreviation === iLanguage.abbreviation) &&
                                <IonIcon key={index} className="menu-icon" slot="end" icon={Check} color="default" />
                            }
                        </IonItem>
                    )
                })
            }
        </IonList>
    )
}
