import * as React from 'react';
import { useEffect, useState} from "react";
import "./TopicComponent.scss";
import { useTranslation } from 'react-i18next';
import { ApiAsset, ApiPlaylist, CollectionType, MediaType } from '../../utils/ApiTypes';
import ContentGrid from '../ContentGrid/ContentGrid';


type TopicsComponentParams = {
    content: ApiPlaylist[];
    contentTypeFilter: string;
    contentCount: (map: Map<string, number>) => void;
};

export const TopicComponent: React.FC<TopicsComponentParams> = (props: TopicsComponentParams) => {

    const { t } = useTranslation();
    const [contentMap, setContentMap] = useState<Map<string, ApiAsset[]>>();
    const [content, setContent] = useState<ApiPlaylist[]>([]);
    const [expandedCollectionId, setExpandedCollectionId] = useState<number | null>(null);

    useEffect(() => {
        categorizeContent(props.content);
        setContent(props.content.filter( i => i.type == CollectionType.Playlist)); //extra precaution
    }, [props.content]);

    function handleShowOrHideGrid(index: number){
        if(expandedCollectionId){
            setExpandedCollectionId(null);
        } else {
            setExpandedCollectionId(index);
        }
    }

    function categorizeContent(assets: ApiAsset[]){
        const playlists: ApiPlaylist[] = assets.filter(a => a.type === CollectionType.Playlist) as ApiPlaylist[];
        const allItems = playlists.flatMap(asset => asset.items);
        const allVideos: ApiAsset[] = allItems.filter(item => item.type === MediaType.Video);
        const allAudios: ApiAsset[] = allItems.filter(item => item.type === MediaType.Audio);
        const allArticles: ApiAsset[] = allItems.filter(item => item.type === MediaType.Article);
        setContentMap(new Map([
            [t("Video"), allVideos],
            [t("Audio"), allAudios],
            [t("Articles"), allArticles]
        ]));

        props.contentCount(new Map([
            [t("Video"), allVideos.length],
            [t("Audio"), allAudios.length],
            [t("Articles"), allArticles.length]
        ]))
    }

    function renderCollections(){
        switch(props.contentTypeFilter) {
            case t("Highlights"):
                //Highlights are the first 8 items of each collection
                return props.content.filter(i => !expandedCollectionId || expandedCollectionId == i.id).map((collection: ApiPlaylist, index: number) => {
                    return (
                        <div key={collection.id} className={"topic-content-grid-wrapper"}>
                            <div key={index} className={"topic-content-grid-container"}>
                                <ContentGrid
                                    showCollectionName={content.length>1}
                                    showButton={true}
                                    content={collection.items} 
                                    maxItems={expandedCollectionId === collection.id ? null : 8}
                                    handleClickSeeButton={() => handleShowOrHideGrid(collection.id)}
                                    contentHeader={collection.title}/>
                            </div>
                            <hr className={"horizontal-hr"}/>
                        </div>
                    )
                    
                })
            default:
                if(contentMap && contentMap.get(props.contentTypeFilter)){
                    return (
                        <div className={"topic-content-grid-wrapper"}>
                            <div className={"topic-content-grid-container"}>
                                <ContentGrid showCollectionName={content.length>1} content={contentMap.get(props.contentTypeFilter) || []}/>
                            </div>
                            <hr className={"horizontal-hr"}/>
                        </div>
                    )
                }
                return null;
        }
    }


    return(
        <div className={"topics-component"}>
            {content && content.length > 0 ? renderCollections() :
                <div>There is no content that matches your search criteria</div>
            }

        </div>
    )
};