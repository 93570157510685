import * as dotenv from "dotenv";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { isPlatform } from "@ionic/react";
import * as Sentry from "@sentry/react";
import ClientConfig from "./utils/ClientConfig";
import i18n from "i18next";
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import clientConfig from "./utils/ClientConfig";
import 'vite/modulepreload-polyfill';
import * as version from '../version';

import { withTolgee, Tolgee, I18nextPlugin, DevTools, BackendFetch } from '@tolgee/i18next';

const tolgee = Tolgee()
  .use(DevTools())
  .use(I18nextPlugin())
  .init({
    language: 'en',
  });

const time = new Date().getTime();
const awaitLoad = withTolgee(i18n, tolgee)
  .use(initReactI18next)
  .use(HttpBackend)
  .init<HttpBackendOptions>({
    backend: {
      loadPath: `https://journey-translations.s3.us-west-1.amazonaws.com/9395b146d3613d635856f1a58026509f/translation/{{lng}}.json?t=${time}`,
    },
    lng: "en",
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
  });

dotenv.config();

const redirectUri = isPlatform('android') ? "com.journeylive.android:MainActivity" : isPlatform('ios') ? 'journeylive:com.journeylive.ios' : window.location.origin;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const tracedEnvs = ["staging", "production"];
const thisEnv = clientConfig.environment;
Sentry.init({
  dsn: "https://422aaeb192b94f83bd850aebae7bf5df@o400167.ingest.sentry.io/6440139",
  enabled: tracedEnvs.includes(thisEnv),
  environment: thisEnv,
  release: import.meta.env["VITE_COMMIT_HASH_DO"] ? import.meta.env["VITE_COMMIT_HASH_DO"].slice(0, 7) : `fe-${version.version}`,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    })
  ],
});


  
awaitLoad.then(() => {
  ReactDOM.render(
      <Auth0Provider
          domain={ClientConfig.auth0Domain}
          clientId={ClientConfig.auth0ClientId}
          useRefreshTokens={false}
          useRefreshTokensFallback={false}
          redirectUri={redirectUri}
          scope={"openid profile email all:api"}
          audience={ClientConfig.auth0Audience}
      >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Auth0Provider>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  // serviceWorkerRegistration.unregister();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
