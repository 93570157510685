import {useEffect, useState} from 'react';
import "./ClinicianCalendar.scss";
import {ApiClinician} from "../../utils/ApiTypes";
import {IonButton} from "@ionic/react";
import {
    addMonths,
    format,
    getWeekOfMonth, isEqual,
    isMonday,
    isSaturday,
    isSunday,
    isThursday,
    isFriday,
    isTuesday,
    isWednesday, 
    subMonths
} from "date-fns";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import isBefore from "date-fns/isBefore";
import addDays from "date-fns/addDays";
import { useTranslation } from 'react-i18next';

export interface ClinicianCalendarProps {
    clinicians: ApiClinician[];
    onAppointmentClick: (timeslot: Date, clinician: ApiClinician) => void;
}

export type AvailableDate = {
    day: number;
    month: number;
    year: number;
    weekOfMonth: number;
}

export type ClinicianAvailability = {
    clinician: ApiClinician;
    availabilityTime: string;
    availabilityDate: Date;
}

export function ClinicianCalendar(props: ClinicianCalendarProps) {

    const [availableDays, setAvailableDays] = useState<string[]>([]);
    const [availableCliniciansPerDayMap, setAvailableCliniciansPerDayMap] = useState<Map<string, ClinicianAvailability[]> | null>(null);
    const [availabilityClinicianTimes, setAvailabilityClinicianTimes] = useState<ClinicianAvailability[] | null>([]);
    const [availabilityText, setAvailabilityText] = useState<string>("");
    const { t } = useTranslation();

    useEffect(() => {
        createCalendarMaps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.clinicians])

    function getDayOfTheWeekString(date: Date){
        if(isMonday(date)) return t('Monday')
        else if(isTuesday(date)) return t('Tuesday')
        else if(isWednesday(date)) return t('Wednesday')
        else if(isThursday(date)) return t('Thursday')
        else if(isFriday(date)) return t('Friday')
        else if(isSaturday(date)) return t('Saturday')
        else if(isSunday(date)) return t('Sunday')
        else return ''
    }

    function createCalendarMaps() {
        let availableDaysArr: string[] = [];
        let cliniciansPerDayMap = new Map<string, ClinicianAvailability[]>();

        props.clinicians.forEach((clinician: ApiClinician, index: number) => {
            if(clinician.slots){
                clinician.slots.forEach((date: string, index: number) => {
                    //Store the days where one or more clinicians are available
                    const dateString = (`${new Date(date).getFullYear()}${new Date(date).getMonth()+1}${new Date(date).getDate()}`);
                    const dateAlreadyInArray: boolean = availableDaysArr.includes(dateString);
                    if(!dateAlreadyInArray){
                        availableDaysArr.push(dateString);
                    }

                    //For each day have an array of Clinicians Available with that time formatted
                    let arrOfCliniciansWithTime: ClinicianAvailability[] = cliniciansPerDayMap.get(dateString) ?? [];
                    arrOfCliniciansWithTime.push({
                        clinician: clinician,
                        availabilityTime: `${new Date(date).toLocaleTimeString(navigator.language,
                            {hour: 'numeric', minute: '2-digit'})}`,
                        availabilityDate: new Date(date),
                    });
                    cliniciansPerDayMap.set(dateString, sortClinicianAvailabilityDayByHour(arrOfCliniciansWithTime));
                })
            }
        })
        setAvailableDays(availableDaysArr);
        setAvailableCliniciansPerDayMap(cliniciansPerDayMap);
    }

    function sortClinicianAvailabilityDayByHour(arrayToSort: ClinicianAvailability[]) {
        return arrayToSort.sort((a: ClinicianAvailability, b: ClinicianAvailability) =>
            ((changeTimeTo24HourClock(a.availabilityTime).hour * 60) + changeTimeTo24HourClock(a.availabilityTime).minutes)
            - ((changeTimeTo24HourClock(b.availabilityTime).hour * 60) + changeTimeTo24HourClock(b.availabilityTime).minutes));
    }

    function changeTimeTo24HourClock(timeString: string): {hour: number, minutes: number} {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = (parseInt(hours) + 12).toString();
        }
        return {hour: parseInt(hours), minutes: parseInt(minutes)};
    }

    function handleDayClicked(mapKey: string, calendarDay: AvailableDate){
        let dayOfTheWeek: string = getDayOfTheWeekString(new Date(calendarDay.year, calendarDay.month -1, calendarDay.day));

        let clinicianAvailability: ClinicianAvailability[] | null = availableCliniciansPerDayMap?.get(mapKey) ?? null;
        if(clinicianAvailability && clinicianAvailability.length){
            setAvailabilityClinicianTimes(clinicianAvailability);
            setAvailabilityText(`${dayOfTheWeek}, ${t(format(new Date(calendarDay.year, calendarDay.month -1, calendarDay.day), "MMMM"))} ${calendarDay.day}`);
        }
    }

    function handleMakeAppointment(availability: ClinicianAvailability){
        props.onAppointmentClick(availability.availabilityDate, availability.clinician);
    }

    return(
        <div className={"clinician-calendar-component"} >
            <div className={"calendar"}>
                <Calendar availableDates={availableDays} onDayClick={handleDayClicked}/>
            </div>
            <div className={"time-slots-buttons-container"}>
                <div className={"date-text header-6-variant"}>{availabilityText}</div>
                <div className={"buttons-container"}>
                    {availabilityClinicianTimes && availabilityClinicianTimes.map((availability: ClinicianAvailability, index: number) => {
                        return(
                            <IonButton
                                key={index}
                                className={"availability-button button-medium"}
                                onClick={() => handleMakeAppointment(availability)}>
                                {`${availability.availabilityTime} ${t('with')} ${availability.clinician.firstName} ${availability.clinician.lastName}`}
                            </IonButton>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}



export interface CalendarProps {
    availableDates: string[];
    onDayClick: (day: string, calendarDay: AvailableDate) => void;
}

export function Calendar(props: CalendarProps) {

    const { t } = useTranslation();
    const [startOfCurrentMonthInCalendar, setStartOfCurrentMonthInCalendar] = useState<Date>(startOfMonth(new Date()));
    const [monthAndYear, setMonthAndYear] = useState<string>(`${t(format(new Date(), 'MMMM'))} ${format(new Date(), 'yyyy')}`);
    const [nextMonth, setNextMonth] = useState<string>(`${t(format(addMonths(new Date(), 1), 'MMM').toUpperCase())}`);
    const [prevMonth, setPrevMonth] = useState<string | null>(null);
    const [calendarWeeks, setCalendarWeeks] = useState<Map<number, AvailableDate[]>>(new Map());
    const [daySelected, setDaySelected] = useState<AvailableDate | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const daysOfTheWeek: string[] = [t("SUN"), t("MON"), t("TUE"), t("WED"), t("THU"), t("FRI"), t("SAT")];

    useEffect(() => {
        createMonthlyCalendar(startOfCurrentMonthInCalendar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.availableDates])



    function createMonthlyCalendar(date: Date) {
        setIsLoading(true);
        let rollingDate = date;
        const lastOfMonth = endOfMonth(date);

        let weeks: Map<number, AvailableDate[]> = new Map();
        while (isBefore(rollingDate, lastOfMonth)) {
            let weekNumber: number = getWeekOfMonth(rollingDate);
            let week: AvailableDate[] = weeks.get(weekNumber) ?? [];
            week.push({
                day: rollingDate.getDate(),
                //Do not remove +1 since .getMonth returns from 0 to 11
                month: rollingDate.getMonth()+1,
                year: rollingDate.getFullYear(),
                weekOfMonth: weekNumber
            })
            weeks.set(weekNumber, week);
            rollingDate = addDays(rollingDate, 1);
        }
        setCalendarWeeks(weeks);
        setIsLoading(false);
    }

    function handleDayClicked(day: AvailableDate, date: string) {
        setDaySelected(day);
        props.onDayClick(date, day);
    }

    function handleNextPrevMonthClicked(startOfNewMonthToShow: Date){
        setStartOfCurrentMonthInCalendar(startOfNewMonthToShow);
        createMonthlyCalendar(startOfNewMonthToShow);
        setMonthAndYear(`${t(format(startOfNewMonthToShow, 'MMMM'))} ${format(startOfNewMonthToShow, 'yyyy')}`);
        setNextMonth(`${t(format(addMonths(startOfNewMonthToShow, 1), 'MMM').toUpperCase())}`);
        setPrevMonth(`${t(format(subMonths(startOfNewMonthToShow, 1), 'MMM').toUpperCase())}`);
        //Making value null to avoid going in the past
        if(isEqual(startOfNewMonthToShow, startOfMonth(new Date()))){
            setPrevMonth(null);
        }
    }


    return(
        <div className={"calendar-component"}>
            <div className={"calendar-header"}>
                <div className={"month-year-text header-6-variant"}>
                    {monthAndYear}
                </div>
                <div className={"next-prev-month-container"}>
                    {prevMonth &&
                    <IonButton
                        onClick={() => handleNextPrevMonthClicked(subMonths(startOfCurrentMonthInCalendar, 1))}
                        disabled={isLoading}
                        className={"next-prev-month-button overline"}
                        fill={"clear"}>
                        {`< ${prevMonth}`}
                    </IonButton>}
                    <IonButton
                        onClick={() => handleNextPrevMonthClicked(addMonths(startOfCurrentMonthInCalendar, 1))}
                        disabled={isLoading}
                        className={"next-prev-month-button overline"}
                        fill={"clear"}>
                        {`${nextMonth} >`}
                    </IonButton>
                </div>
            </div>

            <div className={"calendar-body"}>
                <div className={"days-text-container"}>
                    {daysOfTheWeek.map((day: string, index: number) => {
                        return(
                            <div key={index} className={"days-text slot overline"}>{day}</div>
                        )
                    })}
                </div>
                <div className={"weeks-container"}>
                    <div className={"week first"}>
                        { calendarWeeks.has(1) && calendarWeeks.get(1)?.map((cd: AvailableDate, index: number) => {
                            const date = new Date(cd.year, cd.month - 1, cd.day);
                            const dateString = (`${date.getFullYear()}${date.getMonth()+1}${date.getDate()}`);
                            const dateIsAvailable: boolean = props.availableDates.includes(dateString);
                            return(
                                <div key={index} className={"slot"}>
                                    <div className={"button-container"}>
                                        <IonButton
                                            disabled={!dateIsAvailable}
                                            onClick={() => handleDayClicked(cd, dateString)}
                                            className={`day-button ${cd === daySelected ? "selected" : ""} button-medium`}>
                                            {cd.day}
                                        </IonButton>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={"week"}>
                        { calendarWeeks.has(2) && calendarWeeks.get(2)?.map((cd: AvailableDate, index: number) => {
                            const date = new Date(cd.year, cd.month - 1, cd.day);
                            const dateString = (`${date.getFullYear()}${date.getMonth()+1}${date.getDate()}`);
                            const dateIsAvailable: boolean = props.availableDates.includes(dateString);
                            return(
                                <div key={index} className={"slot"}>
                                    <div className={"button-container"}>
                                        <IonButton
                                            disabled={!dateIsAvailable}
                                            onClick={() => handleDayClicked(cd, dateString)}
                                            className={`day-button ${cd === daySelected ? "selected" : ""} button-medium`}>
                                            {cd.day}
                                        </IonButton>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={"week"}>
                        { calendarWeeks.has(3) && calendarWeeks.get(3)?.map((cd: AvailableDate, index: number) => {
                            const date = new Date(cd.year, cd.month - 1, cd.day);
                            const dateString = (`${date.getFullYear()}${date.getMonth()+1}${date.getDate()}`);
                            const dateIsAvailable: boolean = props.availableDates.includes(dateString);
                            return(
                                <div key={index} className={"slot"}>
                                    <div className={"button-container"}>
                                        <IonButton
                                            disabled={!dateIsAvailable}
                                            onClick={() => handleDayClicked(cd, dateString)}
                                            className={`day-button ${cd === daySelected ? "selected" : ""} button-medium`}>
                                            {cd.day}
                                        </IonButton>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={"week"}>
                        { calendarWeeks.has(4) && calendarWeeks.get(4)?.map((cd: AvailableDate, index: number) => {
                            const date = new Date(cd.year, cd.month - 1, cd.day);
                            const dateString = (`${date.getFullYear()}${date.getMonth()+1}${date.getDate()}`);
                            const dateIsAvailable: boolean = props.availableDates.includes(dateString);
                            return(
                                <div key={index} className={"slot"}>
                                    <div className={"button-container"}>
                                        <IonButton
                                            disabled={!dateIsAvailable}
                                            onClick={() => handleDayClicked(cd, dateString)}
                                            className={`day-button ${cd === daySelected ? "selected" : ""} button-medium`}>
                                            {cd.day}
                                        </IonButton>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    { calendarWeeks.has(5) &&
                        <div className={"week"}>
                            { calendarWeeks.get(5)?.map((cd: AvailableDate, index: number) => {
                                const date = new Date(cd.year, cd.month - 1, cd.day);
                                const dateString = (`${date.getFullYear()}${date.getMonth()+1}${date.getDate()}`);
                                const dateIsAvailable: boolean = props.availableDates.includes(dateString);
                                return(
                                    <div key={index} className={"slot"}>
                                        <div className={"button-container"}>
                                            <IonButton
                                                disabled={!dateIsAvailable}
                                                onClick={() => handleDayClicked(cd, dateString)}
                                                className={`day-button ${cd === daySelected ? "selected" : ""} button-medium`}>
                                                {cd.day}
                                            </IonButton>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    { calendarWeeks.has(6) &&
                        <div className={"week"}>
                            { calendarWeeks.get(6)?.map((cd: AvailableDate, index: number) => {
                                const date = new Date(cd.year, cd.month - 1, cd.day);
                                const dateString = (`${date.getFullYear()}${date.getMonth()+1}${date.getDate()}`);
                                const dateIsAvailable: boolean = props.availableDates.includes(dateString);
                                return(
                                    <div key={index} className={"slot"}>
                                        <div className={"button-container"}>
                                            <IonButton
                                                disabled={!dateIsAvailable}
                                                onClick={() => handleDayClicked(cd, dateString)}
                                                className={`day-button ${cd === daySelected ? "selected" : ""} button-medium`}>
                                                {cd.day}
                                            </IonButton>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>


            </div>

        </div>
    )
}