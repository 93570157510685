import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage
} from "@ionic/react";
import "./TeacherPage.scss";
import {useHistory} from "react-router-dom";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {ApiTeacher, ContentItem} from '../../utils/ApiTypes';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import ContentGrid from '../../components/ContentGrid/ContentGrid';
import ClampLines from '../../components/ClampLines/ClampLines';
import { t } from 'i18next';

export const TeacherPage: React.FC = () => {

    const [teacher, setTeacher] = useState<ApiTeacher | null>();
    const [teacherContent, setTeacherContent] = useState<ContentItem[]>([]);
    const {isMobileWidth, handleGeneralError, currentUser } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();

    useEffect(() => {
        if(!isComponentVisible) return;

        setTeacher(null);
        (async function () {
            try {
                const teacherId = window.location.pathname.split("/")[2];
                setTeacherContent(await JourneyApiClient.getTeacherContent(teacherId));
                setTeacher(await JourneyApiClient.getTeacher(teacherId));
            } catch(error) {
                handleGeneralError(error, undefined, { 
                    contextName: "Could not get teacher", 
                    contextData: {
                        userId: currentUser?.id,
                        company: currentUser?.company
                    }
                });
                history.replace("/");
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    return(
        <IonPage className="teacher-page">

            <ResponsiveNavigationMenu title={teacher?.firstName} />

            <IonContent className="teacher-content-container">
                {!teacher ? <PleaseWait/> : <>
                <div className='teacher-header'>
                    {teacher.bannerThumbnailImageS3Path && <img src={teacher.bannerThumbnailImageS3Path} alt="Teacher banner"/>}
                    <div>
                        <div className={`${isMobileWidth ? "header-4" : "page-title"} teacher-name`}>{teacher.firstName} {teacher.lastName}</div>
                        <ClampLines
                            showButton={true}
                            text={teacher.bio ?? ''}
                            lines={8}
                            ellipsis="..."
                            moreText={t("Expand") ?? ''}
                            lessText={t("Collapse") ?? ''}
                            className="clamp-description body-large"
                        />
                    </div>
                </div>
                <div className="teacher-content-grid-wrapper">
                    <ContentGrid content={teacherContent} />
                </div></>}
            </IonContent>
        </IonPage>
    )
};
