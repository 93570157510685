import "./HrHubFileContents.scss";
import * as React from "react";
import {ApiDirectoryNode, ApiFolderNode} from "../../utils/ApiTypes";
import {useEffect, useState, useContext} from "react";
import {HrHubListItem} from "./HrHubListItem";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {useLocation} from "react-router";
import AnalyticsService from "../../misc/AnalyticsService";

interface Props {
  files: ApiDirectoryNode[];
  currentFolderId: string | null;
  onCurrentFilesChange: (count: number | null) => void;
}

export const HrHubFileContents: React.FC<Props> = ({files, currentFolderId, onCurrentFilesChange }) => {

  const [currentFiles, setCurrentFiles] = useState<ApiDirectoryNode[]>(files);
  const {handleGeneralError, currentUser, language} = useContext(ApplicationContext);
  const location = useLocation();


  function findFolder(id: string, filesToSearch: ApiDirectoryNode[]): ApiFolderNode | null {
    for(const file of filesToSearch) {
      if(file.id === id && file.isFolder) {
        return file;
      }
      else if(file.isFolder) {
        const childFolder = findFolder(id, file.children);
        if(childFolder) {
          return childFolder;
        }
      }
    }
    return null;
  }

  function countResources(files: ApiDirectoryNode[]): number {
    let total = 0;
    for(const file of files) {
      if(file.isFolder) {
        total += countResources(file.children);
      } else {
        total++;
      }
    }
    return total;
  }

  function onFileDownloadClick(file_id: string, file_name: string) {
    AnalyticsService.trackUserAction("file_download", location.pathname, {
      file_id, file_name });
  }

  useEffect(() => {
    onCurrentFilesChange(countResources(currentFiles));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFiles]);


  useEffect(() => {
      if(currentFolderId) {
        const targetFolder = findFolder(currentFolderId, files);
        if(targetFolder) {
          setCurrentFiles(targetFolder.children);
        } else {
          setCurrentFiles([]);
          const error = new Error(`Folder with id ${currentFolderId} not found`);
          handleGeneralError(error, undefined, { 
            contextName: "Unable to find folder", 
            contextData: {
                userId: currentUser?.id,
                language: language,
                company: currentUser?.company,
                currentFolderId: currentFolderId
            } 
          });
        }
      } else {
        setCurrentFiles(files);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, currentFolderId]);
  

  return(
      <div className="hr-hub-file-contents-component">
        {
          currentFiles.length > 0 ?
              currentFiles.map((item, index) => <HrHubListItem key={index} item={item} downloadCallback={onFileDownloadClick} />)
              :
              <div className="no-files">No files found.</div>
        }
      </div>
  );
}