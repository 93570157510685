import "./MindfulBreak.scss";
import {useContext, useEffect, useState} from "react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {VideoResponse, } from "../../utils/ApiTypes";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import AnalyticsService from "../../misc/AnalyticsService";
import {MuxVideoPlayer} from "../../components/MuxVideoPlayer/MuxVideoPlayer";
import {PleaseWait} from "../PleaseWait/PleaseWait";
import * as React from "react";
import {useLocation} from "react-router-dom";
import { useTranslation } from 'react-i18next';

interface MindfulBreakProps {
    isComponentVisible: boolean;
}

export const MindfulBreak: React.FC<MindfulBreakProps> = (props: MindfulBreakProps) => {

    const MINDFUL_BREAK_VIDEO_ID = 12972040;
    const CALENDAR_ET_ID = "sW15143631";
    const CALENDAR_CT_ID = "Rh15225804";
    const CALENDAR_MT_ID = "Ff15225856";
    const CALENDAR_PT_ID = "UO15225866";

    // time zones to be considered for each of our calendars
    const CT_TIME_ZONES = ["CST", "CDT"];
    const MT_TIME_ZONES = ["MST", "MDT"];
    const PT_TIME_ZONES = ["PST", "PDT"];
    const [calendarId, setCalendarId] = useState<string | null>(CALENDAR_ET_ID);
    const [muxVideo, setMuxVideo] = useState<VideoResponse|null>(null);
    const [isVideoContentLoading, setIsVideoContentLoading] = useState<boolean>(true);
    const {currentUser, handleGeneralError, setUserFlags, isMobileWidth, language} = useContext(ApplicationContext);
    const location = useLocation();

    const timeZone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    const { t } = useTranslation();

    useEffect(() => {
        if(!props.isComponentVisible){
            return;
        }
        getVideoContent(MINDFUL_BREAK_VIDEO_ID.toString());
        //set the calendar to use depending on the user's timezone
        if(PT_TIME_ZONES.includes(timeZone)) {
            setCalendarId(CALENDAR_PT_ID);
        } else if(MT_TIME_ZONES.includes(timeZone)) {
            setCalendarId(CALENDAR_MT_ID);
        } else if(CT_TIME_ZONES.includes(timeZone)) {
            setCalendarId(CALENDAR_CT_ID);
        } else {
            setCalendarId(CALENDAR_ET_ID); //default to ET
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isComponentVisible]);

    const getVideoContent = (videoId: string) => {
        setIsVideoContentLoading(true);
        JourneyApiClient.getVideoById(videoId).then((jsonResponse: VideoResponse) => {
            setMuxVideo(jsonResponse);
            setIsVideoContentLoading(false);
        }).catch( error => {
            setIsVideoContentLoading(false);
            handleGeneralError(error, undefined, { 
                contextName: "Could not get video content", 
                contextData: {
                    userId: currentUser?.id,
                    language: language,
                    company: currentUser?.company,
                    videoId: videoId
                } 
            });
        });
    }

    const trackPlayEvent = async () => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: MINDFUL_BREAK_VIDEO_ID,
        }
        await AnalyticsService.trackUserAction("mindful_break_video_play", location.pathname, eventProperties);
    }

    const trackPauseEvent = async () => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: MINDFUL_BREAK_VIDEO_ID
        }
        await AnalyticsService.trackUserAction("mindful_break_video_pause",  location.pathname, eventProperties);
    }

    const trackCuePointEvent = async (position: any) => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: MINDFUL_BREAK_VIDEO_ID,
            completion_percentage: position,
        }
        await AnalyticsService.trackUserAction("mindful_break_video_checkpoint",  location.pathname, eventProperties);
    }

    const trackUnmute = async () => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("mindful_break_video_unmute", location.pathname, eventProperties);
    }

    const trackMindfulBreakAdded = async () => {
        try {
            await JourneyApiClient.setUserAddedMindfulBreak();
            await AnalyticsService.trackUserAction("mindful_break_added", location.pathname);
            setUserFlags("hasAddedMindfulBreak");
        } catch (e) {}
    }

    return(
        <div className='mindful-break-component' >
            {!isMobileWidth &&
                <div className="video">
                    {( !currentUser || isVideoContentLoading) ?
                        <PleaseWait/> : (muxVideo) &&
                            <MuxVideoPlayer
                                videoId={muxVideo.id}
                                duration={muxVideo.duration}
                                isLoading={isVideoContentLoading}
                                isComponentVisible={props.isComponentVisible}
                                isInitialMuted={true}
                                muxPlaybackId={muxVideo.muxPlaybackId}
                                muxVideoId={muxVideo.muxVideoId}
                                title={muxVideo.title}
                                currentUser={currentUser}
                                trackCuePointEvent={trackCuePointEvent}
                                trackPlayEvent={trackPlayEvent}
                                trackPauseEvent={trackPauseEvent}
                                trackUnmute={trackUnmute}
                                numberOfRecommendationsToShow={1}/>
                    }
                </div>
            }

        <div className='calendar-subscribe'>
            <h2>{t("Mindful Break")}</h2>
            <p>
                {t("We invite you to to take a 10-minute break to refresh and reboot with bite-sized videos that change daily.")}
            </p>
            <div className="calendar">
                <p>
                    {t("Mindful Breaks are scheduled for Mondays, Wednesdays & Fridays.")}
                </p>
                <p>
                    {t("Feel free to move this event to the time of the day that best suits you!")}
                </p>
                <h3>
                    {t("Select your provider to add Mindful Break to your calendar")}
                </h3>
                <div className="calendar-providers">
                    <a href={`https://www.addevent.com/event/${calendarId}+apple`} onClick={trackMindfulBreakAdded} title="Apple" target="_blank" rel="noreferrer" style={{display: "inline"}}>
                        <img src="https://cdn.addevent.com/libs/imgs/icon-emd-share-apple-t1.png" alt="Apple" width="45" style={{width:"55px", display: "inline", border: "0"}} />
                    </a>
                    <a href={`https://www.addevent.com/event/${calendarId}+google`} onClick={trackMindfulBreakAdded} title="Google" target="_blank" rel="noreferrer" style={{display: "inline"}}>
                        <img src="https://cdn.addevent.com/libs/imgs/icon-emd-share-google-t1.png" alt="Google" width="45" style={{width:"55px", display: "inline", border: "0"}} />
                    </a>
                    <a href={`https://www.addevent.com/event/${calendarId}+office365`} onClick={trackMindfulBreakAdded} title="Office 365" target="_blank" rel="noreferrer" style={{display: "inline"}}>
                        <img src="https://cdn.addevent.com/libs/imgs/icon-emd-share-office365-t1.png" alt="Office 365" width="45" style={{width:"55px", display: "inline", border: "0"}} />
                    </a>
                    <a href={`https://www.addevent.com/event/${calendarId}+outlook`} onClick={trackMindfulBreakAdded} title="Outlook" target="_blank" rel="noreferrer" style={{display: "inline"}}>
                        <img src="https://cdn.addevent.com/libs/imgs/icon-emd-share-outlook-t1.png" alt="Outlook" width="45" style={{width:"55px", display: "inline", border: "0"}} />
                    </a>
                    <a href={`https://www.addevent.com/event/${calendarId}+outlookcom`} onClick={trackMindfulBreakAdded} title="Outlook.com" target="_blank" rel="noreferrer" style={{display: "inline"}}>
                        <img src="https://cdn.addevent.com/libs/imgs/icon-emd-share-outlookcom-t1.png" alt="Outlook.com" width="45" style={{width:"55px", display: "inline", border: "0"}} />
                    </a>
                </div>
            </div>
        </div>
    </div>
    )
}
