import './VideoComment.scss';
import React, {useContext, useEffect, useState} from "react";
import {IonButton, IonItemDivider, IonSpinner, IonTextarea} from "@ionic/react";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import moment from "moment";
import {ApiComment, ApiUser} from "../../utils/ApiTypes";
import {ApplicationContext} from "../../misc/ApplicationContext";
import AnalyticsService from "../../misc/AnalyticsService";
import {useLocation} from "react-router-dom";

interface VideoCommentProps {
    sourceId: number;
    currentUser: ApiUser;
    isLiveStream?: boolean;
}

export const VideoComment: React.FC<VideoCommentProps> = (props: VideoCommentProps) => {

    const POSTED_COMMENTS_LIMIT = 5;
    const COMMENTS_SHOWN_INCREMENT = 10;
    const [comment, setComment] = useState<string | null>();
    const [isReportLoading, setReportIsLoading] = useState<boolean>(false);
    const [commentBeingReported, setCommentBeingReported] = useState<number | null>(null);
    const [commentsList, setCommentsList] = useState<ApiComment[]>([]);
    const [reportedComments, setReportedComments] = useState<number[]>([]);
    const [postedComments, setPostedComments] = useState<number>(0);
    const [commentsShown, setCommentsShown] = useState<number>(COMMENTS_SHOWN_INCREMENT);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);
    const {handleGeneralError, currentUser, language} = useContext(ApplicationContext);
    const location = useLocation();
    const postedCommentsLimitReached = postedComments >= POSTED_COMMENTS_LIMIT;

    useEffect(() => {
        setComment(null);
        setIsLoading(false);
        getVideoComments();
        let intervalHandle: NodeJS.Timeout;
        if(props.isLiveStream){
            intervalHandle = setInterval(() => getVideoComments(), 15000);
        }
        return () => {
            if (intervalHandle) clearInterval(intervalHandle);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if((isLoading || postedCommentsLimitReached) && !props.isLiveStream){
            setSubmitButtonDisabled(true);
        } else {
            setSubmitButtonDisabled(false);
        }
    }, [props.isLiveStream, isLoading, postedCommentsLimitReached]);


    const trackCommentSubmitEvent = async (sourceId: number) => {
        let eventProperties = props.isLiveStream ? {live_stream_id: sourceId} : {video_id: sourceId};
        await AnalyticsService.trackUserAction("comment_submit", location.pathname, eventProperties);
    }

    const getVideoComments = async () => {
        try{
            if(props.isLiveStream){
                const response = await JourneyApiClient.getLiveStreamComments(props.sourceId)
                setCommentsList(response);
            } else {
                setCommentsList(await JourneyApiClient.getVideoComments(props.sourceId));
            }

        } catch (error) {
            handleGeneralError(error, undefined, { 
                contextName: `Could not get ${props.isLiveStream ? "livestream" : "video"} comments`, 
                contextData: {
                    userId: currentUser?.id,
                    language: language,
                    company: currentUser?.company,
                    isLiveStream: props.isLiveStream,
                    sourceId: props.sourceId
                } 
            });
        }
    }

    const createApiComment = (comment: ApiComment): ApiComment => {
        return {
            id: comment.id,
            user: {
                id: props.currentUser.id ? props.currentUser.id : 1,
                name: `${props.currentUser.firstName}`
            },
            comment: comment.comment,
            createdAt: comment.createdAt,
        }
    }

    const addComment = (newComment: ApiComment) => {
        const commentsListCopy = [...commentsList];
        commentsListCopy.unshift(createApiComment(newComment));
        setCommentsList(commentsListCopy);
        setComment(null);
        setPostedComments(postedComments + 1);
        if(postedComments + 1 >= POSTED_COMMENTS_LIMIT) {
            window.alert("Limit of comments reached.");
        }
        trackCommentSubmitEvent(props.sourceId)
        setIsLoading(false);
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        if(comment && (!postedCommentsLimitReached || props.isLiveStream)){
            try{
                if(props.isLiveStream){
                    const apiCommentResponse = await JourneyApiClient.createLiveStreamComment(comment, props.sourceId);
                    addComment(apiCommentResponse.comment);
                } else {
                    const apiCommentResponse = await JourneyApiClient.createVideoComment(comment, props.sourceId);
                    addComment(apiCommentResponse.comment);
                }
            } catch (error) {
                handleGeneralError(error, undefined, { 
                    contextName: `Could not create ${props.isLiveStream ? "livestream" : "video"} comment`, 
                    contextData: {
                        userId: currentUser?.id,
                        language: language,
                        company: currentUser?.company,
                        isLiveStream: props.isLiveStream,
                        sourceId: props.sourceId,
                        comment: comment
                    } 
                });
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    }

    const increaseCommentsShown = () => {
        setCommentsShown(commentsShown + COMMENTS_SHOWN_INCREMENT);
    }

    const handleReport = (commentId: number) => {
        setReportIsLoading(true);
        setCommentBeingReported(commentId);
        JourneyApiClient.reportComment(commentId).then( () => {
            let dummyArr = [...reportedComments];
            dummyArr.push(commentId);
            setReportedComments(dummyArr);
            setReportIsLoading(false);
            setCommentBeingReported(null);
        }).catch(error => {
            handleGeneralError(error, undefined, { 
                contextName: "Could not report video comment", 
                contextData: {
                    userId: currentUser?.id,
                    language: language,
                    company: currentUser?.company,
                    commentId: commentId
                }
            });
            setReportIsLoading(false);
            setCommentBeingReported(null);
        });
    }

    const getTimeDifferenceFromNow = (messageSentTime: string | Date): string => {
        const startTime: moment.Moment = moment(messageSentTime);
        const endTime: moment.Moment = moment.utc();
        return startTime.from(endTime);
    }

    return(
        <div className="video-comment-component">
            <div className="comment-input-field-container">
                <IonTextarea
                    onIonChange={(e) => setComment(e.detail.value)}
                    className="comment-input-field body-small"
                    placeholder="Add your comment"
                    value={comment}
                    maxlength={500}
                    rows={4}
                    autoGrow={false}
                />
            </div>

            <div className={"post-comment-button-container"}>
                <IonButton disabled={submitButtonDisabled} onClick={handleSubmit} className="post-comment-button button-medium-variant">
                    {isLoading ? <IonSpinner name={"crescent"}/> : "Post Comment"}
                </IonButton>
            </div>

            {commentsList.slice(0, commentsShown).map((comment, index) => {
                return(
                    <div className="comment-container" key={index}>
                        <div className="comment-name overline">{comment.user.name}</div>
                        <div className="comment-date caption">{getTimeDifferenceFromNow(comment.createdAt)}</div>
                        <div className="comment-copy body-medium">{comment.comment}</div>
                        {isReportLoading && commentBeingReported === comment.id ? <IonSpinner name={"crescent"}/> :
                            <IonButton
                                className="report-button body-small"
                                fill="clear"
                                disabled={reportedComments.includes(comment.id)}
                                onClick={() => handleReport(comment.id)}>
                                {reportedComments.includes(comment.id) ? <>&#128681; Reported</> : <>&#x2691; Report</> }
                            </IonButton>
                        }
                        {index < commentsList.length - 1 && <IonItemDivider className="comment-divider"/>}
                    </div>
                )
            })}

            {commentsShown <= commentsList.length && (
                <IonButton disabled={commentsShown >= commentsList.length} onClick={increaseCommentsShown} className="load-more-button">
                    Load More...
                </IonButton>
            )}
        </div>
    )
}
