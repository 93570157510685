import './DailyJourneyPage.scss';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {useParams, useLocation } from "react-router-dom";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {ApiDailyJourney} from "../../utils/ApiTypes";
import {IonButton, IonContent, IonPage} from "@ionic/react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {ContinuousDailyJourney} from "../../components/ContinuousDailyJourney/ContinuousDailyJourney";
import DesktopHeader from "../../assets/images/daily-journey-header.png";
import AnalyticsService from "../../misc/AnalyticsService";
import { useTranslation } from 'react-i18next';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { LoginModalComponent } from "../../components/LoginModalComponent/LoginModalComponent";

type DailyJourneyRouteParams = {
    id: string;
    count: string;
    from: string;
    onboarding?: string;
};

export const DailyJourneyPage: React.FC = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
    const [isDailyJourneyAvailable, setIsDailyJourneyAvailable] = useState<boolean>(true);
    const [dailyJourneyInfo, setDailyJourneyInfo] = useState<ApiDailyJourney | null>(null);
    const { currentUser } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const params = useParams<DailyJourneyRouteParams>();
    const location = useLocation();
    const qp = new URLSearchParams(location.search);
    const { t } = useTranslation();

    useEffect(() => {
        if(isComponentVisible){
            if(params.id) {
                //id is specified as query parameter, display daily journey with id specified
                JourneyApiClient.getDailyJourneyById(params.id)
                    .then(setDailyJourneyInfo)
                    .catch((error) => {
                        setIsDailyJourneyAvailable(false);
                    })
                    .then(() => {
                    });
            }
            else {
                //id not specified, display current day daily journey
                JourneyApiClient.getDailyJourneyInfo()
                    .then(setDailyJourneyInfo)
                    .catch((error) => {
                        setIsDailyJourneyAvailable(false);
                    })
                    .then(() => {
                    });
            }
            const queryParams = new URLSearchParams(window.location.search);
            let shared = queryParams.get("shared");
            if(shared) {
                AnalyticsService.trackUserAction("daily_journey_shared_link", location.pathname, { shared_by_user_id: shared});
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    const handleButtonClick = () => { 
        const email = 'info@journey.live';
        const subject = t('daily-journey-contact-support-email-subject');
        const body = t('daily-journey-contact-support-email-body');
    
        // Creating the mailto URI
        const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
    
        // Opening the default email app
        window.location.href = mailtoLink;
      };

    const openLoginModal = async () => {
        //if (currentUser && currentUser.privileged) return;
        setIsLoginModalOpen(true);
        await AnalyticsService.trackUserAction("view_login_modal", location.pathname);
    }

    return (
        <IonPage className={`daily-journey-page`}>
            
            <ResponsiveNavigationMenu title={t("Daily Journey")} handleOpenLoginModal={openLoginModal}/>
            <LoginModalComponent
                isComponentVisible={isComponentVisible}
                isModalOpen={isLoginModalOpen}
                closeModal={() => setIsLoginModalOpen(false)}
            />
            <IonContent>

                <div className='dj-header  industry-targeting-header-gradient'>
                    <div className={"daily-journey-text page-title"}>{t("Daily Journey")}</div>

                    <img className={"image"} alt="Header" src={DesktopHeader}/>

                </div>
            {
                dailyJourneyInfo &&
                <div>
                    <ContinuousDailyJourney showSingle={!!qp.has('onboarding')} dailyJourney={dailyJourneyInfo} isComponentVisible currentUser={currentUser}/>
                </div>
            }
            {
                !isDailyJourneyAvailable &&
                <div className="daily-journey-error">
                    <div className="daily-journey-text body-medium">
                        {params.id ? t('daily-journey-instance-not-available-language') : t('daily-journey-not-available-language')}
                        {t('daily-journey-check-later')}
                    </div>
                    <IonButton
                        className="toolbar-button button-small contact-support-button button-small-variant"
                        fill="clear"
                        onClick={handleButtonClick}
                    >
                        {t('daily-journey-contact-support')}
                    </IonButton>
                </div>
            }
            </IonContent>
        </IonPage>
    );

};
