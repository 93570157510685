import "./TalkItOutCard.scss";
import {FC, useContext} from 'react';
import PhoneIcon from "../../assets/images/phone-plus-heart.svg";
import { IonButton, IonIcon } from "@ionic/react";
import { useTranslation } from 'react-i18next';
import useCompanyBenefits from "../CustomHooks/useCompanyBenefits";
import { ApplicationContext } from "../../misc/ApplicationContext";
import {LocalizedImage} from "../LocalizedImage/LocalizedImage";

export const TalkItOutCard: FC = () => {
    const { t } = useTranslation();
    const { defaultPhoneNumber } = useCompanyBenefits();
    const { isMobileApp } = useContext(ApplicationContext);
    const fallbackImage = "https://journeylive.imgix.net/production/talk-it-out-images/13.png";

    return(
        <div className="talk-it-out-card-component journey-card">
            <LocalizedImage
                className='background-image'
                i18nKey={'talk-it-out-image'}
                fallback={fallbackImage}
            />
            <div className="box-header box-behind">
                <div className="box-title">
                    <div className={"title overline"}>{t('Talk It Out')}</div>
                </div>
            </div>
            
            <div className="inner-content">
                <div className="header-6-correct message">{t('Feel Better in Just 1 Call')}</div>
                {isMobileApp ?
                    <IonButton className="button-medium-variant medium-primary-button" href={`tel:${defaultPhoneNumber?.phoneNumber}`}>
                        <IonIcon src={PhoneIcon} slot="start" className="call-icon" />
                        {defaultPhoneNumber?.subtitle}
                    </IonButton>
                    :
                    <IonButton className="button-medium-variant medium-primary-button">
                        <IonIcon src={PhoneIcon} slot="start" className="call-icon" />
                        {defaultPhoneNumber?.subtitle}
                    </IonButton>
                }
            </div>
            
        </div>
    );

};

