import { IonButton, IonContent, IonIcon, IonModal } from '@ionic/react';
import React, { useContext, useState } from 'react';
import { ApplicationContext } from '../../misc/ApplicationContext';
import { useTranslation } from 'react-i18next';
import PhoneBlack  from '../../assets/images/phone-black.svg';
import ShareExperience  from '../../assets/images/share-your-experience.svg';
import { useHistory, useLocation } from "react-router-dom";
import './ClinicalCaseRetargetingComponent.scss';
import { GetCompanyBenefitsInfoResponse } from '../../utils/ApiTypes';
import CallSupportButton from '../CallSupport/CallSupportButton';
import { ExitButton } from '../ExitButton/ExitButton';
import { JourneyApiClient } from '../../utils/JourneyApiClient';
import { Feature } from '../Feature/Feature';
import AnalyticsService from '../../misc/AnalyticsService';
import { people } from 'ionicons/icons';
import { INVITE_LOVED_ONES_PATH } from '../../utils/utils';

interface Props {
    benefitsInfo: GetCompanyBenefitsInfoResponse;
}

export const ClinicalCaseRetargetingComponent: React.FC<Props> = (props) => {

    const { currentUser, setUserFlags } = useContext(ApplicationContext);
    const location = useLocation();
    const [isClinicalCaseRetargetingModalOpen, setIsClinicalCaseRetargetingModalOpen] = useState<boolean>(currentUser?.hasClinicalCaseRetargetingModal ?? false);
    const { t } = useTranslation();
    const typeformUrl = "https://journey-live.typeform.com/testimonial";
    const history = useHistory();

    async function handleClose() {
        await JourneyApiClient.setClinicalCareRetargetingModalSeen();
        setIsClinicalCaseRetargetingModalOpen(false);
    }

    async function handleShareYourExperience() {
        await JourneyApiClient.setUserHasSharedExperience();
        setUserFlags("hasSharedExperience");
        window.open(typeformUrl, '_blank', 'noreferrer');
        await AnalyticsService.trackUserAction("clinical_retargeting_share_experience_opened", location.pathname);
    }

    async function handleInviteLovedOnes() {
        history.push(INVITE_LOVED_ONES_PATH);
        await AnalyticsService.trackUserAction("clinical_retargeting_invite_loved_ones_opened", location.pathname);
        handleClose();
    }

    return (
        <IonModal 
            onWillDismiss={handleClose}
            isOpen={isClinicalCaseRetargetingModalOpen}
            className="clinical-case-retargeting-modal">
            <IonContent className="clinical-case-retargeting-content">
                <div className="exit-button-container">
                    <ExitButton onClick={handleClose}/>
                </div>
                <div className="clinical-case-retargeting-container">
                    <div className="icon-container"><IonIcon src={PhoneBlack} className={"clinical-case-retargeting-icon"}/></div>
                    <p className="clinical-case-retargeting-title header-4">{t("Thank you for choosing Journey Proactive EAP")}</p>
                    <p className="clinical-case-retargeting-text body-medium">
                        {t("We hope you found the experience valuable. Remember, you can always call us again to connect with a master’s level clinician about any topic, free of charge and confidentially.")}
                    </p>
                    <div className="proactive-care-buttons-wrapper">
                        <div className="proactive-care-buttons-container">
                             {props.benefitsInfo && props.benefitsInfo.supportPhones.length > 0 &&
                                <CallSupportButton 
                                    className='proactive-care-button header-6-variant'
                                    benefitsInfo={props.benefitsInfo}
                                    onClick={handleClose}
                                    hideList={true}
                                />
                            }
                            <Feature feature="testimonials">
                                <IonButton
                                    className='proactive-care-button header-6-variant'
                                    onClick={handleShareYourExperience}>
                                    <IonIcon src={ShareExperience} className={`share-experience-button-icon`} />
                                    {t("Share Your Experience")}
                                </IonButton>
                            </Feature>
                            <Feature feature="invite-loved-ones">
                                <IonButton
                                    className='proactive-care-button header-6-variant'
                                    onClick={handleInviteLovedOnes}>
                                    <IonIcon className={`share-experience-button-icon`} icon={people} />
                                    {t("Invite Loved Ones")}
                                </IonButton>
                            </Feature>
                        </div>
                    </div>
                    <p className="clinical-case-retargeting-text body-medium">
                        {t("Your family and friends can take advantage of these services too—simply share the phone number for their own free and confidential support!")}
                    </p>
                </div>
            </IonContent>
        </IonModal>
    );
};

